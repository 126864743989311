import React from "react"
import H2 from "../typography/H2"
import { Link } from "gatsby"
import SvgCarretRight from "../commons/svgs/SvgCarretRight"

const SectionTitle = ({ title, linktext, to, className, color }) => {
  return (
    <div className={className ? className : ""}>
      <H2
        className={`${
          color !== undefined ? `shadow-title-${color}` : ""
        } inline`}
      >
        {title}
      </H2>
      <br />
      {linktext && to ? (
        <Link
          to={to}
          className="text-gray-400 hover:text-gray-900 inline-block uppercase font-medium mt-1"
        >
          {linktext} <SvgCarretRight className="h-4 w-4 inline -mt-1" />
        </Link>
      ) : (
        ""
      )}
    </div>
  )
}

export default SectionTitle
