import React from "react"
const H4 = ({ children, className }) => {
  return (
    <h1
      className={`text-base font-extrabold leading-relaxed sm:text-lg mmd:text-xl ${className}`}
    >
      {children}
    </h1>
  )
}

export default H4
