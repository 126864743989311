import React from "react"
import { graphql } from "gatsby"
import SectionTitle from "../components/commons/SectionTitle"
import ColumnsThree from "../components/layouts/container/ColumnsThree"
import Container from "../components/layouts/container/Container"
import Layout from "../components/layouts/Default"
import ListEpisode from "../components/commons/ListEpisode"
import SidebarTopics from "../components/commons/SidebarTopics"
import AdsBanner from "../components/commons/AdsBanner"

const EpisodesList = ({ data, location, pageContext }) => {
  return (
    <Layout
      location={location}
      title="Alle Folgen | Die Summe aller Dinge Podcast"
      desc='Alle unsere Folgen zu unserem Podcast "Die Summer aller Dinge"'
    >
      <Container className="my-8 mmd:my-16">
        <ColumnsThree>
          <div className="col-span-2 mb-6 mmd:mb-0">
            <SectionTitle title="Alle Folgen" className="mb-6" />
            <ListEpisode
              listArray={data.alleFolgen.nodes}
              totalCount={data.alleFolgen.totalCount}
              currentPage={pageContext.currentPage}
            />
          </div>
          <div className="col-span-1 mmd:mt-16">
            <div className="mb-3">
              <SidebarTopics />
            </div>
            <div>
              <AdsBanner />
            </div>
          </div>
        </ColumnsThree>
      </Container>
    </Layout>
  )
}

export default EpisodesList

export const query = graphql`
  query($skip: Int, $pageSize: Int) {
    alleFolgen: allCockpitFolgen(
      sort: {fields: folgennummer___value, order: DESC},
      filter: { public: { value: { eq: true } } }
      skip: $skip
      limit: $pageSize
    ) {
      totalCount
      nodes {
        datum {
          value
        }
        einleitung {
          value
        }
        folgennummer {
          value
        }
        id
        slug {
          slug
        }
        titel {
          value
        }
        thema {
          value {
            display
          }
        }
        titelbild {
          value {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
