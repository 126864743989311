import React from "react"
const H2 = ({ children, className }) => {
  return (
    <h2
      className={`text-xl font-extrabold leading-relaxed sm:text-2xl mmd:text-3xl ${className}`}
    >
      {children}
    </h2>
  )
}

export default H2
