import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import H3 from "../typography/H3"
import P from "../typography/P"
import ButtonPlay from "./ButtonPlay"
import { convertDate } from "../functions/convertDate"

const ListEpisode = ({ listArray, totalCount, currentPage = 1, className }) => {
  const data = useStaticQuery(graphql`
    query {
      allCockpitThemen {
        nodes {
          thema {
            value
          }
          farbe {
            value
          }
        }
      }
    }
  `)
  return (
    <div className={className ? className : ""}>
      <div className="mb-5">
        {listArray.map((item, i) => {
          let color
          data.allCockpitThemen.nodes.forEach(topic => {
            if (topic.thema.value === item.thema.value.display) {
              color = topic.farbe.value
            }
          })
          return (
            <div
              className={`mmd:grid mmd:grid-cols-10 ${
                listArray.length === i + 1 ? "" : "mb-8"
              }`}
              key={i}
            >
              <div className={`col-span-4 mb-3 mmd:mb-0 `}>
                <Link
                  to={`/folgen/${item.folgennummer.value}/${item.slug.slug}/`}
                >
                  <Img
                    fluid={item.titelbild.value.childImageSharp.fluid}
                    className="w-full duration-300 transform rounded shadow hover:scale-110"
                    style={{ height: "inherit" }}
                  />
                </Link>
              </div>
              <div className={`col-span-6 pl-3 flex justify-center flex-col`}>
                <div className="text-base text-gray-700">
                  Folge: {item.folgennummer.value} -{" "}
                  {convertDate(item.datum.value)} - Thema:{" "}
                  <span>{item.thema.value.display}</span>
                </div>
                <div>
                  <H3 className={`inline font-bold shadow-title-${color}`}>
                    <Link
                      to={`/folgen/${item.folgennummer.value}/${item.slug.slug}/`}
                    >
                      {item.titel.value}
                    </Link>
                  </H3>
                </div>

                <P className="mb-3">{item.einleitung.text}</P>
                <div className="inline-block">
                  <ButtonPlay
                    text="Zur Folge"
                    to={`/folgen/${item.folgennummer.value}/${item.slug.slug}/`}
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>

      <div>
        <nav className="flex items-center justify-between px-4 border-t border-gray-200 sm:px-0">
          <div className="flex flex-1 w-0 -mt-px">
            {currentPage > 1 && (
              <Link
                to={`/folgen/seite/${currentPage - 1}/`}
                className="inline-flex items-center pt-4 pr-1 text-sm font-medium text-gray-500 border-t-2 border-transparent hover:text-gray-700 hover:border-gray-300"
              >
                {/* Heroicon name: solid/arrow-narrow-left */}
                <svg
                  className="w-5 h-5 mr-3 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                Zurück
              </Link>
            )}
          </div>
          <div className="hidden md:-mt-px md:flex">
            {Array.from({
              length: Math.ceil(totalCount / process.env.GATSBY_PAGE_SIZE),
            }).map((_, i) => {
              const pageNumber = i + 1
              return (
                <Link
                  to={`/folgen/seite/${pageNumber}/`}
                  className={`inline-flex items-center px-4 pt-4 text-sm font-medium border-t-2 border-transparent ${
                    pageNumber === currentPage
                      ? "text-black border-black"
                      : "text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  }`}
                >
                  {pageNumber}
                </Link>
              )
            })}
          </div>
          <div className="flex justify-end flex-1 w-0 -mt-px">
          {currentPage !== Math.ceil(totalCount / process.env.GATSBY_PAGE_SIZE) && (
            <Link
              to={`/folgen/seite/${currentPage + 1}/`}
              className="inline-flex items-center pt-4 pl-1 text-sm font-medium text-gray-500 border-t-2 border-transparent hover:text-gray-700 hover:border-gray-300"
            >
              Nächste
              {/* Heroicon name: solid/arrow-narrow-right */}
              <svg
                className="w-5 h-5 ml-3 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>)}
          </div>
        </nav>
      </div>
    </div>
  )
}

export default ListEpisode
