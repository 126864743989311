import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import H4 from "../typography/H4"

const SidebarTopics = ({ current }) => {
  const data = useStaticQuery(graphql`
    query {
      allCockpitThemen {
        nodes {
          thema {
            value
          }
          farbe {
            value
          }
          slug {
            slug
          }
        }
      }

      alleFolgen: allCockpitFolgen(
        filter: { public: { value: { eq: true } } }
      ) {
        nodes {
          thema {
            value {
              display
            }
          }
        }
      }
    }
  `)

  return (
    <div className="p-3 bg-gray-100 rounded">
      <H4 className="mb-2">Themen</H4>
      {data.allCockpitThemen.nodes.map((item, i) => {
        let counter = 0
        data.alleFolgen.nodes.forEach(folge => {
          if (folge.thema.value.display === item.thema.value) {
            counter = counter + 1
          }
        })

        return (
          <Link
            to={`/folgen/${item.slug.slug}/`}
            className={`block ${
              data.allCockpitThemen.nodes.length === i + 1
                ? " hover:underline"
                : "mb-3 hover:underline"
            }`}
            key={i}
          >
            {item.thema.value}{" "}
            <span
              className={`bg-topic-${item.farbe.value} text-white rounded-full text-xs inline-flex h-5 w-5 items-center justify-center`}
            >
              {counter}
            </span>
          </Link>
        )
      })}
    </div>
  )
}

export default SidebarTopics
