import React from "react"
import { Link } from "gatsby"
import SvgPlay from "./svgs/SvgPlay"

const ButtonPlay = ({ text, to, onClick, className }) => {
  const classes = `inline-flex py-1 pl-1 pr-2 -ml-1 rounded-lg items-center flex-row border-transparent transition-colors duration-300 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-bg-black700 focus:ring-white ${
    className ? className : ""
  }`

  if (to) {
    return (
      <Link to={to} className={classes}>
        <SvgPlay className="h-8 w-8 mr-3" /> {text}
      </Link>
    )
  }

  if (onClick) {
    return (
      <button onClick={onClick} className={classes}>
        <SvgPlay className="h-8 w-8 mr-3" /> {text}
      </button>
    )
  }
  return (
    <button onClick={onClick} className={`bg-red-600 ${classes}`}>
      <SvgPlay className="h-8 w-8 mr-3" /> Missing props
    </button>
  )
}

export default ButtonPlay
